<template>
	<div class="track_list">
		<div class="head_tracks_list">
			<div class="_left_">
				<div class="title_" :class="{hide_like: UPPER_FILTER === 'favorite'}">Title
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[title]_asc'}"
						      @click="orderBy('title', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[title]_desc'}"
						      @click="orderBy('title', 'desc')">arrow_drop_down</span>
					</div>
				</div>
				<div class="artist_">Artist
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[artist.name]_asc'}" @click="orderBy('artist.name', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[artist.name]_desc'}" @click="orderBy('artist.name', 'desc')">arrow_drop_down</span>
					</div>
				</div>
				<div class="released_" v-if="SHOW_COLUMNS_TRACK.includes('released')">Released
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[releasedAt]_asc'}" @click="orderBy('releasedAt', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[releasedAt]_desc'}" @click="orderBy('releasedAt', 'desc')">arrow_drop_down</span>
					</div>
				</div>
				<div class="bpm_" v-if="SHOW_COLUMNS_TRACK.includes('bpm')">BPM
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[bpm]_asc'}" @click="orderBy('bpm', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[bpm]_desc'}"
						      @click="orderBy('bpm', 'desc')">arrow_drop_down</span>
					</div>
				</div>
				<div class="key_" v-if="SHOW_COLUMNS_TRACK.includes('key')">KEY
					<div class="sort">
						<span class="material-icons" :class="{active: currentOrderBy === 'order[key]_asc'}" @click="orderBy('key', 'asc')">arrow_drop_up</span>
						<span class="material-icons" :class="{active: currentOrderBy === 'order[key]_desc'}"
						      @click="orderBy('key', 'desc')">arrow_drop_down</span>
					</div>
				</div>
			</div>


			<div class="_right_ actions_">
				<v-menu
					transition="slide-y-transition"
					:close-on-content-click="false"
					:nudge-width="120"
					content-class="menu-pointer"
					offset-y
					nudge-left="60"
					nudge-bottom="15"
					origin="top center">
					<template v-slot:activator="{ on, attrs }">
						<span class="material-icons" v-bind="attrs" v-on="on">arrow_drop_down</span>
					</template>
					<v-list>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="label" value="label" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="label">Label</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="released" value="released" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="released">Released</label>
							</div>
						</v-list-item>

						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="viewes" value="viewes" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="viewes">Viewes</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="downloads" value="downloads" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="downloads">Downloads</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="collected" value="collected" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="collected">Collected</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="bpm" value="bpm" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="bpm">BPM</label>
							</div>
						</v-list-item>
						<v-list-item>
							<div class="material_checkbox">
								<input type="checkbox" id="key" value="key" v-model="tracksColumns"
								       @change="checkTrackColumn($event)">
								<label for="key">Key</label>
							</div>
						</v-list-item>

					</v-list>
				</v-menu>
			</div>
		</div>

		<template v-if="UPPER_FILTER !== 'popular' && UPPER_FILTER !== 'trending'">

			<template v-if="UPPER_FILTER !== 'favorite'">
				<template v-if="TRACKS_LOADING === true">
					<v-skeleton-loader
						type="image"
						class="item_track_list"
						v-for="index in 25"
						:key="index"
					></v-skeleton-loader>
				</template>
				<template v-if="TRACKS_LOADING === false">
					<template v-if="TRACKS.length">
						<itemTrack
							v-for="(track, index) in TRACKS"
							:key="+track.id"
							:track="track"
							:playList="'defaultTrackList'"
							:index="index">
						</itemTrack>
						<pagination v-if="COUNT_TRACKS > 30"
						            :count="COUNT_TRACKS"
						            :currentPage="TRACKS_CURRENT_PAGE"
						            :commit="'SET_TRACKS_CURRENT_PAGE'">
						</pagination>
					</template>
					<nothingHereYet v-if="!TRACKS.length"></nothingHereYet>
				</template>
			</template>

			<template v-if="UPPER_FILTER === 'favorite'">
				<template v-if="FAVORITE_TRACKS_LOADING === true">
					<v-skeleton-loader
						type="image"
						class="item_track_list"
						v-for="index in 25"
						:key="index"
					></v-skeleton-loader>
				</template>
				<template v-if="FAVORITE_TRACKS_LOADING === false">
					<template v-if="FAVOURITE_TRACKS.length">
						<itemTrack
							v-for="(track, index) in FAVOURITE_TRACKS"
							:key="track.id"
							:track="track.song"
							:hideLike="false"
							:playList="'favoriteTrackList'"
							:index="index">
						</itemTrack>
						<pagination v-if="COUNT_FAVOURITE_TRACKS > 30"
						            :count="COUNT_FAVOURITE_TRACKS"
						            :currentPage="FAVORITE_TRACKS_CURRENT_PAGE"
						            :commit="'SET_FAVORITE_TRACKS_CURRENT_PAGE'">
						</pagination>
					</template>
					<nothingHereYet v-if="!FAVOURITE_TRACKS.length"></nothingHereYet>
				</template>
			</template>
		</template>
		<nothingHereYet v-if="UPPER_FILTER === 'popular' || UPPER_FILTER === 'trending'"></nothingHereYet>

	</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import itemTrack from "@/components/musicReleases/itemTrack";
import pagination from "@/components/pagination";
import nothingHereYet from "@/components/small/nothingHereYet";

import eventBus from "@/eventBus";

import isMobile from "@/mixins/isMobile";

export default {
	name: 'trackList',
	components: {
		itemTrack,
		pagination,
		nothingHereYet
	},
	data() {
		return {
			initialLoad: true,
			dynamicTrackKey: 0,
			currentOrderBy: `order[bpm]: asc`,
			tracksColumns: this.$store.getters.SHOW_COLUMNS_TRACK,
			tracks: [],
			isUserPageChange: false
		}
	},
	mixins: [isMobile],
	watch: {
		UPPER_FILTER() {
			if (this.initialLoad) return;
			console.log('watch tracks UPPER_FILTER');
			this.getTracks();
		},
		SUBGENRE(newVal, oldVal) {
			if (this.initialLoad) return;
			// Only trigger if the ID actually changed
			if (newVal?.id !== oldVal?.id) {
				console.log('watch tracks SUBGENRE - value changed');
				this.isUserPageChange = false;
				this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
				this.$store.commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1);
				this.getTracks();
			}
		},
		BPM(newVal, oldVal) {
			if (this.initialLoad) return;
			// Check if array values actually changed
			if (!oldVal || newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1]) {
				console.log('watch tracks BPM - value changed');
				this.isUserPageChange = false;
				this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
				this.$store.commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1);
				this.getTracks();
			}
		},
		APPLY_KEYS(newKeys, oldKeys) {
			if (this.initialLoad) return;
			// Only trigger if keys actually changed
			const newKeyIds = newKeys.map(k => k.id).sort().join(',');
			const oldKeyIds = oldKeys.map(k => k.id).sort().join(',');
			if (newKeyIds !== oldKeyIds) {
				console.log('watch tracks apply keys - value changed');
				this.isUserPageChange = false;
				this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
				this.$store.commit('SET_FAVORITE_TRACKS_CURRENT_PAGE', 1);
				this.getTracks();
			}
		},
		TAGS(newVal, oldVal) {
			if (this.initialLoad) return;
			// Only trigger if tags actually changed
			const newTags = [...newVal].sort().join(',');
			const oldTags = [...oldVal].sort().join(',');
			if (newTags !== oldTags) {
				console.log('watch tracks TAGS - value changed');
				this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
				this.getTracks();
			}
		},
		TRACKS_ORDER_BY(newVal, oldVal) {
			if (this.initialLoad) return;
			// Only trigger if order actually changed
			if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
				console.log('trackList.vue watch tracks TRACKS_ORDER_BY - value changed', this.TRACKS_ORDER_BY);
				this.$store.commit('SET_TRACKS_CURRENT_PAGE', 1);
				this.getTracks();
			}
		},
		TRACKS_CURRENT_PAGE(newVal, oldVal) {
			if (this.initialLoad) return;
			if (newVal !== oldVal) {
				console.log('Track page changed:', newVal, 'isUserPageChange:', this.isUserPageChange);
				sessionStorage.removeItem('exploreFilterState');  // Clear scroll position on page change
				this.getTracks();
				if (this.isUserPageChange) {
					console.log('Scrolling due to user page change');
					this.scrollTop();
				}
				this.isUserPageChange = false;
			}
		},
		FAVORITE_TRACKS_CURRENT_PAGE(newVal, oldVal) {
			if (this.initialLoad) return;
			if (newVal !== oldVal) {
				console.log('Favorite track page changed:', newVal, 'isUserPageChange:', this.isUserPageChange);
				sessionStorage.removeItem('exploreFilterState');  // Clear scroll position on page change
				this.getTracks();
				if (this.isUserPageChange) {
					console.log('Scrolling due to user page change');
					this.scrollTop();
				}
				this.isUserPageChange = false;
			}
		},
		SEARCH_STRING(newVal, oldVal) {
			if (this.initialLoad) return;
			if (newVal !== oldVal) {
				console.log('watch tracks SEARCH_STRING - value changed');
				this.getTracks();
			}
		}
	},
	computed: {
		...mapGetters([
			'TRACKS', 'COUNT_TRACKS', 'TRACKS_CURRENT_PAGE', 'TRACKS_LOADING', 'TRACKS_ORDER_BY', 'SHOW_COLUMNS_TRACK',
			'FAVOURITE_TRACKS', 'COUNT_FAVOURITE_TRACKS', 'FAVORITE_TRACKS_CURRENT_PAGE', 'FAVORITE_TRACKS_LOADING',
			'UPPER_FILTER', 'SUBGENRE', 'KEYS', 'APPLY_KEYS', 'BPM', 'TAGS', 'SEARCH_STRING'
		])
	},
	mounted() {
		console.log('=== Mounted Debug Logs ===');
		console.log('Route name:', this.$route.name);
		console.log('Current filters:', {
			subgenre: this.SUBGENRE.id,
			bpm: this.BPM,
			keys: this.APPLY_KEYS
		});
		console.log('Current tracks:', {
			regular: this.TRACKS.length,
			favorite: this.FAVOURITE_TRACKS.length
		});

		// Check if we're truly returning to the page with existing data
		const hasExistingData = this.UPPER_FILTER === 'favorite' ? 
			this.FAVOURITE_TRACKS.length > 0 : 
			this.TRACKS.length > 0;
		
		const isReturningToPage = this.$route.name === 'explore' && 
			hasExistingData && 
			(this.SUBGENRE.id || this.BPM.length || this.APPLY_KEYS.length);
		
		console.log('Has existing data:', hasExistingData);
		console.log('Is returning to page:', isReturningToPage);

		if (!isReturningToPage) {
			console.log('Initial data fetch needed - fetching tracks');
			this.getTracks();
		} else {
			console.log('Returning to page with existing data - skipping initial fetch');
		}

		// Allow time for all state restoration to complete
		setTimeout(() => {
			console.log('Enabling track list watchers');
			this.initialLoad = false;
		}, 500);

		eventBus.on('customEvent', this.getTracks);
		
		// Update pagination event handling
		eventBus.on('paginationClick', () => {
			console.log('Pagination click detected - enabling scroll for next page change');
			this.isUserPageChange = true;
		});
		
		console.log('=== End Mounted Debug Logs ===');
	},
	methods: {
		...mapActions(['GET_TRACKS', 'GET_FAVORITE_TRACKS']),
		scrollTop() {
			this.$nextTick(() => {
				console.log('Scrolling track list to top');
				const el = document.getElementById('explore-nav-id');
				const el2 = document.getElementById('explore-albums-nav-id');
				const el3 = document.getElementById('mobile-filter-bar-id');
				
				// Try to scroll to specific elements first
				if (this.mobile) {
					if (el3 && el2) {
						el2.scrollIntoView({behavior: 'smooth'});
						console.log('Scrolling to explore-albums-nav-id');
					} else if (el3) {
						el3.scrollIntoView({behavior: 'smooth'});
						console.log('Scrolling to mobile-filter-bar-id');
					}
				} else if (el) {
					el.scrollIntoView({behavior: 'smooth'});
					console.log('Scrolling to explore-nav-id');
				} else {
					// If no specific elements found, scroll to top of page
					console.log('No scroll target found, scrolling to top of page');
					window.scrollTo({
						top: 0,
						behavior: 'smooth'
					});
				}
			});
		},

		orderBy: function (key, direction) {
			console.log('run trackList.vue orderBy');
			this.currentOrderBy = `order[${key}]_${direction}`;

			let isFavoriteTracks = ``;
			if (this.UPPER_FILTER === 'favorite') {
				isFavoriteTracks = `song.`;
			}

			this.$store.dispatch('UPDATE_TRACKS_ORDER_BY', {[`order[${isFavoriteTracks}${key}]`]: `${direction}`});
		},

		getTracks() {
			console.log('=== getTracks Debug Logs ===');
			console.log('Current UPPER_FILTER:', this.UPPER_FILTER);
			console.log('Current SUBGENRE:', this.SUBGENRE);
			console.log('Current BPM:', this.BPM);
			console.log('Current APPLY_KEYS:', this.APPLY_KEYS);
			console.log('Current TAGS:', this.TAGS);

			this.$store.commit('SET_TRACK_SCROLL_ID', null);
			
			const subGenre = this.SUBGENRE.id ? {'playLists.musicRelease.subGenres.id': this.SUBGENRE.id} : null;
			console.log('Constructed subGenre param:', subGenre);
			
			const favoriteSubGenre = this.SUBGENRE.id ? {'musicRelease.subGenres.id': this.SUBGENRE.id} : null;
			console.log('Constructed favoriteSubGenre param:', favoriteSubGenre);
			
			const bpm = this.BPM.length ? {'bpm[between]': `${this.BPM[0]}` + '..' + `${this.BPM[1]}`} : null;
			console.log('Constructed BPM param:', bpm);

			let keys;
			if (this.APPLY_KEYS && Array.isArray(this.APPLY_KEYS)) {
				const keyName = (this.UPPER_FILTER === 'favorite') ? 'song.key' : 'key';
				keys = this.APPLY_KEYS.reduce((acc, key, index) => {
					acc[`${keyName}[${index + 1}]`] = key.id;
					return acc;
				}, {});
			}
			console.log('Constructed keys param:', keys);

			const tags = this.TAGS.length ? {'multiple_search_playLists.album.tags': Array(this.TAGS)} : null;
			console.log('Constructed tags param:', tags);
			
			const page = {page: this.TRACKS_CURRENT_PAGE};
			console.log('Page param:', page);

			// favorite tracks params
			const pageFavorite = {page: this.FAVORITE_TRACKS_CURRENT_PAGE};
			const bpmFavorite = this.BPM.length ? {'song.bpm[between]': `${this.BPM[0]}` + '..' + `${this.BPM[1]}`} : null;
			console.log('Favorite specific params:', { pageFavorite, bpmFavorite });

			let params;

			switch (this.UPPER_FILTER) {
				case 'pending':
					params = Object.assign({}, {'status': this.ALBUM_STATUS}, subGenre, bpm, keys, tags, page, this.TRACKS_ORDER_BY);
					break;
				case 'undiscovered':
					params = Object.assign({}, subGenre, bpm, keys, tags, page, this.TRACKS_ORDER_BY);
					break;
				case 'explore':
					params = Object.assign({}, subGenre, bpm, keys, tags, page, this.TRACKS_ORDER_BY);
					break;
				case 'favorite':
					params = Object.assign({}, favoriteSubGenre, bpmFavorite, keys, tags, pageFavorite, this.TRACKS_ORDER_BY);
					break;
			}
			console.log('Final constructed params:', params);
			console.log('TRACKS_ORDER_BY:', this.TRACKS_ORDER_BY);
			console.log('=== End getTracks Debug Logs ===');

			if (this.UPPER_FILTER === 'pending' || this.UPPER_FILTER === 'undiscovered' || this.UPPER_FILTER === 'explore') {
				console.log('Calling GET_TRACKS with params');
				this.GET_TRACKS(params)
					.then(() => {
						this.dynamicTrackKey += 1;
						console.log('GET_TRACKS completed successfully');
					})
					.catch(err => {
						console.error('GET_TRACKS error:', err);
					})
					.finally(() => {
						console.log('GET_TRACKS request finished');
					});
			}
			if (this.UPPER_FILTER === 'favorite') {
				console.log('Calling GET_FAVORITE_TRACKS with params');
				this.GET_FAVORITE_TRACKS(params)
					.then(() => {
						console.log('GET_FAVORITE_TRACKS completed successfully');
					})
					.catch(err => {
						console.error('GET_FAVORITE_TRACKS error:', err);
					})
					.finally(() => {
						console.log('GET_FAVORITE_TRACKS request finished');
					});
			}
		},

		checkTrackColumn() {
			this.$store.commit('SET_SHOW_COLUMNS_TRACK', this.tracksColumns);
		},
	}
}
</script>

<style lang="scss">
.track_list {
	.item_track_list {
		height: 50px;
		margin-bottom: 6px;
		border-radius: 50px;
	}
}
</style>
